import React from 'react';
import './Home.css';
import ProfilePicture from '../assets/profile.jpg'
import { IconExternalLink, IconGithub, IconLinkedin, IconMail, IconMapPin, IconPhone } from '../components/Icons';

export default function Home() {
  return (
    <div className="portfolio">
      <header className="hero">
        <div>
            <img src={ProfilePicture} className='profilePic'/>
        </div>
        <div className="hero-content">
          <h1>Daniel Sanroman</h1>
          <p>Ingeniero de Software Full-Stack | Project Manager | Ingeniero Electrónico de Telecomunicaciones | Scrum Master Certificado</p>
          <div className="hero-buttons">
            <a href="#contact" className="btn btn-primary">Contáctame</a>
            <a href="#projects" className="btn btn-secondary">Ver Proyectos</a>
          </div>
        </div>
      </header>

      <section id="about" className="about">
        <div className="container">
          <h2>Sobre Mí</h2>
          <p>
            Soy Ingeniero Electrónico especializado en Telecomunicaciones, con experiencia en desarrollo de software. 
            Mi habilidad para resolver problemas numéricos y lógicos, junto con mis conocimientos en circuitos electrónicos 
            y física, me permiten ofrecer soluciones tecnológicas avanzadas. También cuento con experiencia en gestión de 
            proyectos como Scrum Master certificado.
          </p>
        </div>
      </section>

      <section id="experience" className="experience">
        <div className="container">
          <h2>Experiencia Profesional</h2>
          <ul>
            <li>
              <h3>Minsait - Ingeniero de Software</h3>
              <p>Septiembre 2024 - Presente (1 mes) | Barcelona, Cataluña, España</p>
            </li>
            <li>
              <h3>Volkswagen Group Services Barcelona - Ingeniero de Software</h3>
              <p>Enero 2024 - Septiembre 2024 (9 meses) | Martorell, Cataluña, España</p>
            </li>
            <li>
              <h3>Basetis - Desarrollador y Director de Proyecto en IoT</h3>
              <p>Febrero 2019 - Enero 2024 (5 años) | Barcelona, Cataluña, España</p>
              <p>Director de Proyecto IoT (Marzo 2020 - Diciembre 2023)</p>
            </li>
          </ul>
        </div>
      </section>

      <section id="education" className="education">
        <div className="container">
          <h2>Educación</h2>
          <ul>
            <li>
              <h3>OBS Business School - Master en Project Management</h3>
              <p>Septiembre 2021 - Agosto 2022</p>
            </li>
            <li>
              <h3>Universitat de Barcelona - Grado en Ingeniería Eléctrica, Electrónica y de Comunicaciones</h3>
              <p>2014 - 2020</p>
            </li>
          </ul>
        </div>
      </section>

      <section className="skills">
        <div className="container">
          <h2>Habilidades</h2>
          <div className="skills-grid">
            {['Programación en JavaScript', 'React', 'Node.js', 'Python', 'Gestión de proyectos', 'Scrum', 'Docker', 'AWS'].map((skill) => (
              <div key={skill} className="skill-item">{skill}</div>
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container">
          <h2>Contacto</h2>
          <div className="contact-content">
            <div className="contact-info">
              <p>¿Interesado en colaborar o trabajar juntos? ¡Contáctame!</p>
              <div className="contact-details">
                <div className="contact-item">
                  <IconMail />
                  <span>dsanroga@gmail.com</span>
                </div>
                <div className="contact-item">
                  <IconPhone />
                  <span>+34 637 203 159</span>
                </div>
                <div className="contact-item">
                  <IconMapPin />
                  <span>Barcelona, España</span>
                </div>
              </div>
              <div className="social-links">
                <a href="https://linkedin.com/in/tuperfil" aria-label="LinkedIn">
                  <IconLinkedin />
                </a>
                <a href="https://github.com/tuusuario" aria-label="GitHub">
                  <IconGithub />
                </a>
              </div>
            </div>
            <form className="contact-form">
              <input type="text" placeholder="Nombre" required />
              <input type="email" placeholder="Email" required />
              <textarea placeholder="Mensaje" rows={4} required></textarea>
              <button type="submit" className="btn btn-primary">Enviar Mensaje</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
