import { useEffect, useState } from 'react';
import './App.css';
import Home from './pages/Home';

function App() {

  const [state, setState] = useState(false)


  useEffect(() => {
    console.log(state);
    return () => {
      console.log("destroyed");
    }
  },[state])

  function handleClick(){
    setState(!state)
  }

  return (
    <Home/>
  );
}

export default App;
